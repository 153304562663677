import { render, staticRenderFns } from "./RenderedMail.vue?vue&type=template&id=39c7e9bf&scoped=true"
import script from "./RenderedMail.vue?vue&type=script&lang=js"
export * from "./RenderedMail.vue?vue&type=script&lang=js"
import style0 from "./RenderedMail.vue?vue&type=style&index=0&id=39c7e9bf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c7e9bf",
  null
  
)

export default component.exports