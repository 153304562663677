import { render, staticRenderFns } from "./ResendButtonComponent.vue?vue&type=template&id=e31db7c0&scoped=true"
import script from "./ResendButtonComponent.vue?vue&type=script&lang=js"
export * from "./ResendButtonComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e31db7c0",
  null
  
)

export default component.exports