import { render, staticRenderFns } from "./MessageActionComponent.vue?vue&type=template&id=1400c94d&scoped=true"
import script from "./MessageActionComponent.vue?vue&type=script&lang=js"
export * from "./MessageActionComponent.vue?vue&type=script&lang=js"
import style0 from "./MessageActionComponent.vue?vue&type=style&index=0&id=1400c94d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1400c94d",
  null
  
)

export default component.exports